import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import Provider from "../services/Provider";
import Loader from "../components/Loader";
const containerId = "embeddingContainer";
const DataDashboard = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [loading, setLoading] = useState(false);
  const [isInternalGroup, setInternalGroup] = React.useState(false);
  useEffect(() => {
    Provider.groups().then((groups) => {
      const isInternal = groups !== undefined && groups.length > 0;
      setInternalGroup(isInternal);
      if (!isInternal) {
        return;
      }
      setLoading(true);
      Provider.dataDashboard().then((url) => {
        const embeddedDashboardExperience = embedDashboard({
          url: url,
          container: '#' + containerId,
          height: "AutoFit",
          loadingHeight: `${height}px`,
          undoRedoDisabled: true,
          resetDisabled: true
        });

        embeddedDashboardExperience.on("load", () => {
          setLoading(false);
        });

        embeddedDashboardExperience.on("error", (error) => {
          setLoading(false);
        });

      }).catch((error) => {
        setLoading(false);
      });
    });

  }, []);
  return (
    <>
      {
        !isInternalGroup && <div style={{ marginLeft: '1.5rem' }}>
          <Typography color="secondary" gutterBottom>Coming Soon!</Typography>
        </div>
      }

      {
        isInternalGroup &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', padding: '0' }}>
          {loading ? <Loader text={"Loading..."} /> : ""}
          <div style={{ width: '100%', height: '100%' }}>
            <div id={containerId}>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default DataDashboard;